import config from "./config";


const techsauce = {

    assets_env: {
        main_logo : config.base_url+"assets/images/main-logo-talent-sauce.png",
        main_logo_active : config.base_url+"assets/images/main-logo-talent-sauce-active.png",
        power_logo_gray: config.base_url+"assets/images/power-by-scoutout-gray.svg"
    },
    credit_usage: {
        ai_tools:{
            standard: 10,
            discount: 5
        },
        resume: {
            standard: 200,
            discount: 200
        }
    },
    class_env: {
        share_job: "share-job-ts"
    },
    text:{
        contact_email : "สนใจเติมเครดิต Service Talentsauce"
    }
   
}


const talent_search = {

    assets_env: {
        main_logo : config.base_url+"assets/images/logo-talent-search.png",
        main_logo_active : config.base_url+"assets/images/logo-talent-search.png",
        power_logo_gray: config.base_url+"assets/images/power-by-scoutout-gray.svg"
    },
    credit_usage: {
        ai_tools:{
            standard: 10,
            discount: 5
        },
        resume: {
            standard: 200,
            discount: 200
        }
    },
    class_env: {
        share_job: "share-job-ts"
    },
    text:{
        contact_email : "สนใจเติมเครดิต Service Talentsauce"
    }
   
}


const normal = {
    assets_env: {
        main_logo : config.base_url+"assets/images/main-logo-hr9-blue.png",
        main_logo_active : config.base_url+"assets/images/main-logo-hr9-blue.png",
        power_logo_gray: config.base_url+"assets/images/power-by-scoutout-gray.svg"
    },
    credit_usage: {
        ai_tools:{
            standard: 5,
            discount: 1
        },
        resume: {
            standard: 50,
            discount: 50
        }
    },
    class_env: {
        share_job: "share-job"
    },
    text:{
        contact_email : "สนใจเติมเครดิต Service HR9 Clinic"
    }
}


const templateConfig = 
process.env.REACT_APP_ENVIRONMENT === 'production' ? normal : 
process.env.REACT_APP_ENVIRONMENT === 'local' ? normal :
process.env.REACT_APP_ENVIRONMENT === 'talentsauce_test' ? techsauce:
process.env.REACT_APP_ENVIRONMENT === 'talentsauce' ? techsauce:
process.env.REACT_APP_ENVIRONMENT === 'talentsauce_local' ? techsauce:
process.env.REACT_APP_ENVIRONMENT === 'dev' ? normal
    : normal;

export default {
    // Add common config values here
    ...templateConfig
};