import { Button, Form } from "react-bootstrap";
import languageConfig from "../../../language-config";
import "../../features/features.scss";
import { Typeahead } from "react-bootstrap-typeahead";
import ApiServices from "../../../services/api.service";
import JDSuggestionModel from "../../../model/jd-suggestion.model";
import { useContext, useEffect, useRef, useState } from "react";
import { ProviderContext } from "../../../context/provider.context";
import InputJDGenerateModel from "../../../model/jd-generator/input-jd-generator.model";
import ProfileSCModel from "../../../model/profile-sc.model";
import ConvertResultServices from "../../../services/convert-result.service";
import ResultJDGenerateModel from "../../../model/jd-generator/result-jd-generator.model";
import UtilityService from "../../../utils/utilities";
import ResultClinicComponent from "../result-clinic/result-clinic";
import EXAMPLE_JSON from "../../../assets/json/example/jd-generator.json";
import LoadingComponent from "../loading/loading";
import TRICK_JSON from "../../../assets/json/trick-feature.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import templateConfig from "../../../template-config";
import GPTService from "../../../services/gpt.service";
import { useLocation, useNavigate } from "react-router-dom";
const JDGeneratorComponent = ({ submitWithOutCredit, authenError }: any) => {
    const { profileModel, creditModel, setCreditModel }: any = useContext(ProviderContext)
    const [jdSuggestion, setJdSuggestion] = useState<JDSuggestionModel[]>([]);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [jobTitle, setJobTitle] = useState("");
    const [autocompleteList, setAutocompleteList] = useState<string[]>([]);
    const [jobExperience, setJobExperience] = useState("");
    const [jobSkill, setJobSkill] = useState("");
    const [language, setLanguage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState("");
    const [isExample, setIsExample] = useState(true);
    const [isValidate, setIsValidate] = useState(false);
    const [profileMod, setProfileMod] = useState<ProfileSCModel>(profileModel);
    const { setResultRawString }: any = useContext(ProviderContext)
    const [isShare, setIsShare] = useState(false);
    const [isHR9, setIsHR9] = useState(false);
    const textFieldRef = useRef<HTMLInputElement>(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get("isHR9") == "true") {
            if (!profileModel) {
                // Navigate to "/xxx" with the updated query parameters
                navigate({
                    pathname: '/hr9',
                    search: queryParams.toString()
                });
            } else {
                setIsHR9(true)
            }
            setResult("")

        } else {
            setResult(EXAMPLE_JSON["result"]);
        }

    }, [location.search])
    useEffect(() => {
        if (textFieldRef && textFieldRef.current) {
            const handleWheel = e => e.preventDefault();
            textFieldRef.current.addEventListener("wheel", handleWheel);

            return () => {
                if (textFieldRef && textFieldRef.current) {
                    textFieldRef.current.removeEventListener("wheel", handleWheel);
                }
            };
        }

    }, [textFieldRef]);
    const resetALL = () => {
        setJobSkill("");
        setJobTitle("");
        setJobExperience("");
        setLanguage("");
        setResult("");
        setIsValidate(false);

    }
    const handleJobRoleChange = (event: any) => {
        setIsSubmiting(false);

        if (event) {
            try {
                if (event[0]["customOption"] != undefined) {
                    setJobTitle(event[0]["label"]);
                    console.log(event[0]["label"]);
                } else {
                    if (jdSuggestion) {
                        jdSuggestion.map((item: JDSuggestionModel, index: number) => {
                            if (item.job_title === event[0]) {
                                setJobTitle(item.job_title);
                                console.log(item.job_title);
                            }
                        })
                    }
                }
            } catch {

            }

        }
    }

    useEffect(() => {
        if (profileModel) {
            setProfileMod(profileModel)
        }
    }, [profileModel])
    useEffect(() => {
        if (profileMod) {
            console.log(creditModel);
            console.log(profileMod);
        }
    }, [profileMod])
    useEffect(() => {
        getJDSuggestion();
    }, [])

    const getJDSuggestion = () => {
        GPTService.getJDSuggestion().then((res: any) => {
            if (res) {
                const resData = res.data as JDSuggestionModel[];
                setJdSuggestion(resData);
                const autoComp: string[] = [];
                resData.map((item: JDSuggestionModel, index: number) => {
                    autoComp.push(item.job_title);
                })
                setAutocompleteList(autoComp)
            }

        })
    }

    const onTextChange = (event: any, setTitle: any) => {
        const jt: string = event.target.value;
        setTitle(jt);
    }
    const handleLanguageChange = (event: any) => {
        setLanguage(event.target.value)
    }

    const submit = () => {
        setIsSubmiting(true);
        if (jobTitle && jobExperience && Number(jobExperience) >= 0 && language) {
            setIsLoading(true);
            setResult("")
            setIsValidate(true);

            let strArr: string[] = [];
            if (jobSkill) {
                const splitStr = jobSkill.split(",")
                splitStr.map((item: string, index: number) => {
                    strArr.push(item);
                })
            }

            const objectResult: InputJDGenerateModel = {
                job_title: jobTitle,
                years: Number(jobExperience),
                skills: strArr,
                language: language,
                uuid: profileMod!.uuid
            }

            GPTService.postCustom(objectResult, "/job_description/generate").then((res) => {
                if (res) {
                    if (res.data == "500") {
                        setResult("500")
                    } else if (res.status == 401) {
                        authenError();
                    } else {
                        const answerModel: ResultJDGenerateModel = res.data;
                        ConvertResultServices().JDGenerator(answerModel, objectResult).then((result) => {
                            if (result) {
                                UtilityService().clickSendEvent("jd_generator_" + objectResult.job_title, "click")
                                setIsLoading(false);
                                setIsShare(true);
                                setResultRawString(result);
                                setResult(result);
                                setIsExample(false);
                                
                                GPTService.getCredit().then((res3) => {

                                    if (res3) {
                                        const data = {
                                            feature: "JD_GENERATOR",
                                            input: objectResult,
                                            result: result
                                        };
                                        window.parent.postMessage(data, '*');
                                        setCreditModel(res3.data)
                                    }
                                })
                            }
                            setIsSubmiting(false);
                        });
                    }

                }
            }).catch((err) => {
                console.log(err);
            })

        }
    }

    return (

        <>
            {
                (result || isHR9) &&

                <div className="feature-control">


                    <div className={!isHR9 ? "feature-form-control" : "feature-form-control feature-form-control-hr9-iframe"}>
                        <div className="feature-form-left-control">
                            {
                                !isHR9 &&

                                <>
                                    <h1 className="feature-main-title">{languageConfig.content.jd_generator.title}</h1>
                                    <span className="feature-main-desc">{languageConfig.content.jd_generator.description}</span>
                                    <div className="feature-credit-usage-control">
                                        <FontAwesomeIcon icon={faCoins} className="feature-credit-usage-icon"></FontAwesomeIcon>
                                        <span className="feature-credit-usage-text">ใช้เครดิต:
                                            <span className="feature-credit-usage-text-bold-line-through">{templateConfig.credit_usage.ai_tools.standard} เครดิต</span>
                                            <span className="feature-credit-usage-text-bold">{templateConfig.credit_usage.ai_tools.discount} เครดิต</span></span>
                                    </div>
                                </>
                            }

                            {
                                isValidate && !isHR9 ?

                                    <>
                                        <div className="dashboard-form-item-submit-control">
                                            <div className="dashboard-form-item-border-control">
                                                <div className="dashboard-form-item-submit-item">
                                                    <div className="dashboard-form-item-submit-title">
                                                        Job Title (ตำแหน่งงาน)
                                                    </div>
                                                    <div className="dashboard-form-item-submit-desc">
                                                        {jobTitle}
                                                    </div>
                                                </div>
                                                <div className="dashboard-form-item-submit-item">
                                                    <div className="dashboard-form-item-submit-title">
                                                        Job Experience (ประสบการณ์ทำงานในตำแหน่งนี้)
                                                    </div>
                                                    <div className="dashboard-form-item-submit-desc">
                                                        {jobExperience}
                                                    </div>
                                                </div>
                                                <div className="dashboard-form-item-submit-item">
                                                    <div className="dashboard-form-item-submit-title">
                                                        Job Skills (ใส่เครื่องหมาย "," หากต้องการใส่มากกว่า 1 ทักษะ)
                                                    </div>
                                                    <div className="dashboard-form-item-submit-desc">
                                                        {jobSkill ? jobSkill : "-"}
                                                    </div>
                                                </div>
                                                <div className="dashboard-form-item-submit-item">
                                                    <div className="dashboard-form-item-submit-title">
                                                        ภาษาที่ต้องการสร้าง JD
                                                    </div>
                                                    <div className="dashboard-form-item-submit-desc">
                                                        {language}
                                                    </div>
                                                </div>
                                                <div className="dashboard-form-item-submit-item">
                                                    <Button className="feature-submit-button" onClick={() => resetALL()} aria-expanded={isLoading} aria-controls="jd-generator">เริ่มใหม่อีกครั้ง</Button>

                                                </div>
                                            </div>

                                        </div>

                                    </> :
                                    !isValidate &&
                                    <div className="dashboard-form-item-control">
                                        <div className="dashboard-form-item" >
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">Job Title (ตำแหน่งงาน)</Form.Label>

                                            <Form.Group>
                                                <Typeahead
                                                    onChange={(e) => handleJobRoleChange(e)}
                                                    id="valid-styles-example"
                                                    inputProps={{
                                                        className: 'dashboard-input',
                                                    }}
                                                    options={autocompleteList}
                                                    allowNew
                                                />
                                            </Form.Group>

                                            {
                                                (!jobTitle && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                    กรุณากรอก Job title
                                                </Form.Text>
                                            }
                                        </div>
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">Job Experience (ประสบการณ์ทำงานในตำแหน่งนี้)</Form.Label>

                                            <Form.Control
                                                ref={textFieldRef}
                                                type="number" min={0} max={100} className="dashboard-input" onChange={(e) => onTextChange(e, setJobExperience)}></Form.Control>
                                            {
                                                (!jobExperience && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                    กรุณากรอก Job Experience
                                                </Form.Text>
                                            }
                                            {
                                                (Number(jobExperience) < 0 && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                    ไม่สามารถกรอกค่าน้อยกว่า 0
                                                </Form.Text>
                                            }
                                        </div>
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">Job Skills (ใส่เครื่องหมาย "," หากต้องการใส่มากกว่า 1 ทักษะ) </Form.Label>

                                            <Form.Control type="text" className="dashboard-input" onChange={(e) => onTextChange(e, setJobSkill)}></Form.Control>

                                        </div>
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">ภาษาที่ต้องการสร้าง JD</Form.Label>

                                            <Form.Select className="dashboard-input" onChange={handleLanguageChange} >
                                                <option value="">เลือกภาษา</option>
                                                <option value="THAI" >ไทย (Beta version)</option>
                                                <option value="ENGLISH" >English</option>
                                            </Form.Select>

                                            {
                                                (!language && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                    กรุณาเลือกภาษา
                                                </Form.Text>
                                            }
                                        </div>
                                        {
                                            profileMod ? <div className="d-flex" >
                                                {
                                                    creditModel && creditModel.credit > 0 ?
                                                        <Button className="feature-submit-button" onClick={() => submit()} aria-expanded={isLoading} aria-controls="jd-generator">{languageConfig.content.jd_generator.button}</Button>
                                                        : <Button className="feature-submit-button-gray" onClick={submitWithOutCredit} aria-expanded={isLoading} aria-controls="jd-generator">{languageConfig.content.jd_generator.button}</Button>
                                                }
                                            </div> : isHR9 ?
                                                <Button className="feature-submit-button" onClick={() => submit()} aria-expanded={isLoading} aria-controls="jd-generator">{languageConfig.content.jd_generator.button}</Button> :
                                                <></>
                                        }

                                    </div>
                            }
                        </div>
                        <div className="">{

                            isLoading && !result ? <>
                                <LoadingComponent feature={TRICK_JSON[0].loading} listTrick={TRICK_JSON[0]} ></LoadingComponent>
                            </> :
                                result && <ResultClinicComponent
                                    isExample={isExample}
                                    type={languageConfig.content.jd_generator.title}
                                    resultStr={result} isClose={false}
                                    isShare={isShare} isShareImg={false}
                                    isHR9={isHR9}
                                    setShowShare={setIsShare} ></ResultClinicComponent>
                        }

                        </div>
                    </div>
                </div>
            }
        </>

    )
}

export default JDGeneratorComponent;