// index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";
import AppTs from './App.talentsauce';
import config from './config';
import App from './App';
import AppLocal from './App.local';
import { LanguageProvider } from './context/language.context';
import 'pure-react-carousel/dist/react-carousel.es.css';
import AppTalentPool from './App.talentpool';

const env = process.env.REACT_APP_ENVIRONMENT; // Get the current environment
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'talentsauce') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}
// Import and use the appropriate environment-specific index.tsx file
switch (env) {
 
  case 'talentpool_dev':
    root.render(
      <LanguageProvider>
        <AppTalentPool />
      </LanguageProvider>
    );
    break;
  
 
  case 'talentsauce_local':
    root.render(
      <LanguageProvider>
        <AppTs />
      </LanguageProvider>
    );
    break;
  case 'talentsauce_test':
    root.render(
      <LanguageProvider>
        <AppTs />
      </LanguageProvider>
    );
    break;
  case 'talentsauce':
    root.render(
      <LanguageProvider>
        <AppTs />
      </LanguageProvider>
    );
    break;
  case 'production':
    root.render(
      <LanguageProvider>
        <App />
      </LanguageProvider>
    );
    break;
  default:
    // Handle other environments or provide a default fallback
    root.render(
      <LanguageProvider>
        <AppLocal />
      </LanguageProvider>
    );

    break;
}
reportWebVitals();
ReactGA.initialize([
  {
    trackingId: config.ga_4 + "",
  }
]);
