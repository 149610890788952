import "./post-job.scss";
import JOB_APPLY from "../../../../assets/images/job-apply.png";
import CHECK from "../../../../assets/images/apply-complete.png";
import { Button, Form } from "react-bootstrap";
import Lottie from "lottie-react";
import SearchingAnimation from "../../../../assets/json/loading-blue.json";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFilePicker } from "use-file-picker";
import * as htmlToText from 'html-to-text';
import { GetJobPostMODEL } from "../../../../model/get-jobpost.model";
import { getUTMParams, handleNavigation } from "../../../../services/handler-route.service";
import { UplaodResumeModel } from "../../../../model/upload-resume.model";
import ApplyJobModel from "../../../../model/job-post/apply-job.model";
import UtilityService from "../../../../utils/utilities";
import templateConfig from "../../../../template-config";
import config from "../../../../config";
import GPTService from "../../../../services/gpt.service";
const PostJobComponent = () => {
    let inputRef: any;
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [fileUpload, setFileUpload] = useState<any>();
    const [validationEmail, setValidationEmail] = useState(false);
    const [email, setEmail] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [jobPost, setJobPost] = useState<GetJobPostMODEL>();
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const { utmSource, utmMedium, version, feature } = getUTMParams(location);
    const navigate = useNavigate();
    const { openFilePicker, filesContent, loading, errors, clear } = useFilePicker({
        multiple: false,
        readAs: 'DataURL',
        accept: ['.json', '.pdf', 'image/*'],
        limitFilesConfig: { min: 0, max: 1 },
        maxFileSize: 20,
        onFilesSelected: ({ plainFiles, filesContent, errors }) => {

            if (filesContent) {
                setFileUpload(filesContent[0])
            }
        
        },
        onFilesRejected: ({ errors }) => {
            // this callback is called when there were validation errors
        }
    });
   
    const onTextChange = (e) => {
        if (validateEmail(e.target.value)) {
            setValidationEmail(false);
            setEmail(e.target.value)
        } else {
            setValidationEmail(true);
        }
    }

    useEffect(() => {
        if (id) {
            getJobPost();
        }
    }, [id])

  
    const validateEmail = (email: string) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const getJobPost = () => {

        GPTService.getJobPost(id).then((res) => {

            if (res) {
                const result = res.data as GetJobPostMODEL;
                setJobPost(result);
            }
        })
    }

    const submit = async () => {

        setIsSubmit(true);
        if (email && fileUpload) {
            setIsLoading(true);
            const file = DataURIToBlob(filesContent[0]["content"]);
            const formData = new FormData();
            formData.append("resume", file, filesContent[0].name);
            formData.append("type", config.chat_gpt.upload_resume.type+"");
            const uploadResult = await GPTService.uploadResume(formData);
            if (uploadResult) {
                const resultKey = uploadResult as UplaodResumeModel;
                const inputObject: ApplyJobModel = {
                    email: email,
                    resume_key: resultKey.resume_key
                }
                const result = await GPTService.applyJob(inputObject, id);
                if (result.data) {
                    UtilityService().clickSendEvent("apply_job_success", "click")
                    setIsLoading(false);
                    setIsComplete(true);
                }
            }
        }
    }

    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)
        return new Blob([ia], { type: mimeString })
    }
    const convertNewLine = (message: string) => {


        return message.replaceAll("\n", "<br>");
    }
    const convertHtmlToPlainText = (html: string) => {
        return htmlToText.convert(html);
    };

    const clickHome = () => {

        handleNavigation(navigate, '/' , utmSource, utmMedium, feature, version);
    }
    const handleWheel = event => {
        // This prevents the scroll wheel from changing the number
        event.preventDefault();
      };
    return (
        <>
            <div className="post-job">
                <div className="post-job-control">
                    <div className="post-job-apply-control">

                        <div className="post-job-apply-text-control">
                            <span className="post-job-apply-title">
                                กรอกข้อมูลเพื่อสมัครงาน
                            </span>


                        </div>

                        <img src={JOB_APPLY} className="post-job-apply-img"></img>

                    </div>
                    {
                        (isComplete && !isLoading && isSubmit) &&
                        <div className="post-job-success-control">
                            <div className="post-job-success-text-control">
                                <img src={CHECK} className="post-job-success-img"></img>
                                <span className="post-job-success-text">ใบสมัครของคุณได้ถูกส่งไปยังผู้ว่าจ้างเรียบร้อยแล้ว</span>
                            </div>
                        </div>
                    }

                    {
                        (isSubmit && isLoading) && <div className="post-job-success-control">
                            <div className="post-job-success-text-control">
                                <Lottie animationData={SearchingAnimation} loop={true} className="post-job-success-img" />
                                <span className="post-job-success-text">กำลังส่งใบสมัคร</span>
                            </div>
                        </div>
                    }
                    {
                        (!isLoading && !isComplete) &&
                        <div className="post-job-input-control">
                            {
                                jobPost && <span className="post-job-description">{jobPost.job_post}</span>
                            }
                            <Form className="post-job-form-control">
                                {
                                    jobPost &&     <div className="post-job-form-item-control">

                                    <span className="post-job-consent">รายละเอียดงาน</span>
                                    <span className="post-job-description" dangerouslySetInnerHTML={{__html: convertNewLine(jobPost.job_post_generated)}}></span>
                                </div>
                                }
                            
                                <div className="post-job-form-item-control">
                                    <Form.Label htmlFor="position" className="post-job-form-title">อีเมล</Form.Label>

                                    <Form.Control type="text" className="post-job-form-input" onChange={(e) => onTextChange(e)}></Form.Control>
                                    {
                                        (isSubmit && email && validationEmail) && <span className="post-job-form-error">อีเมลไม่ถูกต้อง</span>
                                    }
                                    {
                                        (isSubmit && !email) && <span className="post-job-form-error">อีเมลไม่ถูกต้อง</span>
                                    }
                                </div>
                                <div className=
                                    {fileUpload ? "post-job-form-item-control" : "post-job-form-item-resume-control"}
                                >
                                    <div className="post-job-resume-title-control">
                                        <Form.Label htmlFor="position" className="post-job-form-title">อัปโหลดเรซูเม่</Form.Label>
                                        <span className="post-job-form-desc">อัปโหลดเรซูเม่ของคุณเป็นไฟล์ .pdf, .docx, .jpeg หรือ .png </span>
                                        <span className="post-job-form-desc">ขนาดไม่เกิน 20MB </span>
                                    </div>
                                    {
                                        (fileUpload) ?

                                            <button className="post-job-resume-button post-job-resume-button-full" type="button" onClick={openFilePicker}>{fileUpload!.name}</button>
                                            :
                                            <>
                                                <button className="post-job-resume-button" type="button" onClick={openFilePicker}>เลือกไฟล์</button>
                                            </>

                                    }


                                </div>
                                {
                                    (isSubmit && !fileUpload) && <span className="post-job-form-error">กรุณาเลือกไฟล์</span>
                                }
                            </Form>
                            <div className="post-job-form-control">

                                <span className="post-job-consent">การส่งใบสมัคร ระบบจะถือว่าท่านได้เข้าใจและยินยอม <a href={config.app.home+"terms"} target="_blank" className="post-job-consent-a">ข้อกำหนด/เงื่อนไขการใช้งาน</a> และ <a href={config.app.home+"privacy"}  target="_blank" className="post-job-consent-a">นโยบายความเป็นส่วนตัว</a></span>
                            </div>
                            <Button className="post-job-submit-button" onClick={submit}>ส่งใบสมัคร</Button>
                        </div>
                    }



                </div>
                <img src={templateConfig.assets_env.main_logo} className="post-job-footer" onClick={clickHome}></img>

            </div>
        </>
    )
}

export default PostJobComponent;