import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import "./popup.modal.scss";
import axios from 'axios';
import config from '../config';
import UtilityService from '../utils/utilities';

function PopupModal({ showMod, handleClose }: any) {
    const [imgUrl, setImgUrl] = useState("");
    const [actionUrl, setActionUrl] = useState("");

    useEffect(() => {
        getPopupContent();
    }, [])
    const getPopupContent = async () => {

        // const result = await axios.get(config.content_gen_url + "popup?channel=hr9_clinic").then((item) => {

        //     if (item.status == 200) {
        //         const dataItem = item.data.result;

        //         if (dataItem.image_url) {
        //             setImgUrl(dataItem.image_url)
        //             setActionUrl(dataItem.url_link);
        //             UtilityService().clickSendEvent('click_' + dataItem.ga_action, "click")
        //         }
        //     } else {
        //         handleClose();
        //     }
        // })
    }

    const clickPopup = () => {

        const url = new URL(actionUrl);
        const params = url.searchParams;

        params.set('utm_source', 'hr9_clinic');
        window.open(url, "_blank");
    }

    return (
        <>
            {
                imgUrl &&
                <div className='popup-control'>
                    <Modal show={showMod} onHide={handleClose} backdrop="static" keyboard={false} className='popup-control'>
                        <Modal.Body>
                            <div className="d-flex flex-column">
                                <img className="popup-img" id="imgPopup" src={imgUrl} alt="Popup" onClick={clickPopup} />
                                <a href="#" className="popup-close" onClick={handleClose}>ปิด</a>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            }

        </>
    );
}

export default PopupModal;
