import axios from "axios";
import apiConfig from "../api-config"
import config from "../config"
import tokenService from "./token.service";

export default function LeelaService() {

    return {
        async getCandidateView(key,candidateID,companyID) {
            const formAppend = new URLSearchParams();
            formAppend.append("candidateListIndexNumber", "0");
            formAppend.append("candidateSortType", "lastActivityDateTime_DESC");
            return axios.get(config.authen.leela.host_url + apiConfig.leela.candidate_view + candidateID + "?" + formAppend, {
              headers: {
                    'scoutout-authorization': key,
                    'companyuuid': companyID,
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => console.log(err))

        },
        async getActiveSubscription(key,companyID) {
           
            return axios.get(config.authen.leela.host_url + apiConfig.leela.active_subscription,{
              headers: {
                    'scoutout-authorization': key,
                    'companyuuid': companyID,
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => console.log(err))

        },
        async getInfo(key,companyID) {
           
            return axios.get(config.authen.leela.host_url + apiConfig.leela.active_subscription,{
              headers: {
                    'scoutout-authorization': key,
                    'companyuuid': companyID,
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => console.log(err))

        },
        async getProfile(key,companyID) {
           
            return axios.get(config.authen.leela.host_url + apiConfig.leela.profile,{
              headers: {
                    'scoutout-authorization': key,
                    'companyuuid': companyID,
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => console.log(err))

        },
        async getJob(key,companyID, jobID) {
           
            return axios.get(config.authen.leela.host_url + apiConfig.leela.job + jobID,{
              headers: {
                    'scoutout-authorization': key,
                    'companyuuid': companyID,
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => console.log(err))

        }
    }
}