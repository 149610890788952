import apiConfig from "../api-config";
import config from "../config";
import instance from "./auth-context.service";


const getProfile = () => {
    return instance.get(apiConfig.leela.profile);
  };
  const getActiveSubscription = () => {
    return instance.get(apiConfig.leela.active_subscription);
  };
  const registerProfile = () => {
    return instance.post(apiConfig.leela.register_profile);
  };
  const registerCompany = () => {
    return instance.post(apiConfig.leela.register_company);
  };

  const getCandidateView = (candidate_id) => {
    const formAppend = new URLSearchParams();
    formAppend.append("candidateListIndexNumber", "8");
    formAppend.append("candidateSortType", "8");
    return instance.get(apiConfig.leela.candidate_view+candidate_id+"?" +formAppend);
  }

  
  const getDashboard = (company_uuid) => {
   
    return instance.get(apiConfig.leela.dashboard+"?search=&sort=postedDateTime&sortDir=DESC&page=0&pageSize=100&jobStatus=ACTIVE");
  };

  const getDetailJob = (job_id) => {
   
    return instance.get(apiConfig.leela.job+job_id)
  }
 
  
  const ScoutConnectService = {
    getProfile,
    getDashboard,
    registerProfile,
    registerCompany,
    getDetailJob,
    getCandidateView
  };
  
  export default ScoutConnectService;