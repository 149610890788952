import "./talent-search.scss";
import { useEffect, useRef, useState } from "react";
import config from "../../../config";
import tokenService from "../../../services/token.service";
import ProfileSCModel from "../../../model/profile-sc.model";
import CandidateModal from "../../../modal/candidate.modal";
import CandidatePassModel from "../../../model/candidate-pass.model";
import { Button } from "react-bootstrap";
import CandidateHistoryModal from "../../../modal/candidate-history.modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import templateConfig from "../../../template-config";
const TalentSearchComponent = ({ profileModel }: any) => {
    const [urlIframe, setUrlIframe] = useState("");
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [candidateID, setCandidateID] = useState("");
    const [profileMod, setProfileMod] = useState<ProfileSCModel>();
    const [openHistory, setOpenHistory] = useState(false);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    useEffect(() => {
        if (profileModel) {
            setProfileMod(profileModel);
        }

    }, [profileModel])
    useEffect(() => {

        if (profileMod) {
            if (tokenService.getUser()) {
                const params = new URLSearchParams()
                params.append('keyToken', tokenService.getLocalAccessToken());
                params.append('isHeader', 'false');
                params.append('client_id', config.app_name+"");
                params.append('timeStamp', (new Date().toISOString()));
                params.append('uuid', profileMod.uuid);
                console.log(tokenService.getLocalAccessToken())
                const url = config.app.talent_search + "?" + new URLSearchParams(params)
                console.log(url);
                setUrlIframe(url);
            }
        }

    }, [profileMod]);

// Function to handle messages received from the iframe
const handleMessage = (event) => {
    // Check if the message is from a trusted source (your iframe's origin)
    if (event.origin === config.origin_talent_search) {
        // Access the URL data sent from the iframe
        const candidateId = event.data as CandidatePassModel;
        console.log('Received URL from iframe:', candidateId);
        if (candidateId && candidateId.candidate_id) {
            setIsOpenModal(true);
            setCandidateID(candidateId.candidate_id);
        }

    }
};

// Attach the message event listener when the component mounts
useEffect(() => {
    window.addEventListener('message', handleMessage);

    // Cleanup the listener when the component unmounts
    return () => {
        window.removeEventListener('message', handleMessage);
    };
}, []);

const clickHistory = () => {
    setOpenHistory(true)
}
const clickBookmark = () => {
    if (profileMod) {
        if (tokenService.getUser()) {
            const params = new URLSearchParams()
            params.append('keyToken', tokenService.getLocalAccessToken());
            params.append('isHeader', 'false');
            params.append('client_id', config.app_name+"");
            params.append('timeStamp', (new Date().toISOString()));
            params.append('uuid', profileMod.uuid)
            const url = config.app.talent_search_bookmark + "?" + new URLSearchParams(params)
            console.log(url);
            setUrlIframe(url);
        }
    }
}
const clickHome = () => {
    if (profileMod) {
        if (tokenService.getUser()) {
            const params = new URLSearchParams()
            params.append('keyToken', tokenService.getLocalAccessToken());
            params.append('isHeader', 'false');
            params.append('client_id', config.app_name+"");
            params.append('timeStamp', (new Date().toISOString()));
            params.append('uuid', profileMod.uuid)
            const url = config.app.talent_search + "?" + new URLSearchParams(params)
            console.log(url);
            setUrlIframe(url);
        }
    }
}
const handleClose = () => {
    setIsOpenModal(false);
}

const handleCloseHistory = () => {
    setOpenHistory(false);
}

const openSuccess = (candidate_id) => {
    if(iframeRef && iframeRef.current&& iframeRef.current.contentWindow){
        const objectResult = {
            is_complete: true,
            candidate_id: candidate_id
        }
        iframeRef.current.contentWindow.postMessage(objectResult, config.origin_talent_search+"");
    }
 
}

return (

    <div className="talent-search-control">
        <div className="feature-credit-usage-main-control">
            <div className="feature-credit-usage-control">
                <FontAwesomeIcon icon={faCoins} className="feature-credit-usage-icon"></FontAwesomeIcon>
                <span className="feature-credit-usage-text">ใช้เครดิต: <span className="feature-credit-usage-text-bold">{templateConfig.credit_usage.resume.standard} เครดิต</span></span>
            </div>
            <div className="talent-search-button-control">
                {
                    urlIframe.includes("bookmarks") ?
                        <Button className="button-home-talent-search" onClick={clickHome}>ค้นหาผู้สมัคร</Button> :
                        <Button className="button-home-talent-search" onClick={clickBookmark}>ผู้สมัครที่บุ๊คมาร์ค</Button>
                }

                <Button className="button-history-talent-search" onClick={clickHistory}>ประวัติการเปิดดูผู้สมัคร</Button>
            </div>

        </div>
        {
            urlIframe && <iframe
                src={urlIframe}
                width="100%"
                height="100%"
                allow="clipboard-write"
                ref={iframeRef}
            ></iframe>
        }

        {
            isOpenModal &&
            <CandidateModal handleClose={handleClose} isHistory={false} open={isOpenModal} id={candidateID} profileModel={profileMod} openSuccess={openSuccess}></CandidateModal>
        }
        {
            openHistory &&
            <CandidateHistoryModal open={openHistory} handleClose={handleCloseHistory}></CandidateHistoryModal>
        }
    </div>
)
}

export default TalentSearchComponent;