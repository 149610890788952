import storage from '../storage';
import config from '../config';
import ApiServices from './api.service';
import { buildURLWithUTMParams } from './handler-route.service';
import ScoutConnectService from './scoutconnect.service';
var shajs = require('sha.js')
window.Buffer = window.Buffer || require("buffer").Buffer; 


export default function AuthenMysa() {
    const PKCEencoding = (verificationCode: string) => {
        let result = shajs('sha256').update(verificationCode).digest('base64');
      
        result = result.replaceAll(/\+/g, '-')
        .replaceAll(/\//g, '_')
        .replace(/\=$/, '');
        return result;
    }

    const randomString = (length: number) => {
        var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var result = '';
        for (var i = 0; i < length; i++) {
          result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;

    }
    return {
        async authenticateUrl(utmSource, utmMedium, feature) {
            const codeVerifier = randomString(56);
            const codeChallenge = PKCEencoding(codeVerifier);
            const newURL = buildURLWithUTMParams(config.authen.redirect_uri, utmSource, utmMedium, feature, 'LOGIN');
            localStorage.setItem(storage.code_verifier, codeVerifier);
            const state = randomString(Number(config.authen.length));
            const urlReturn = config.authen.sso_url + "?" + new URLSearchParams({
                redirect_uri: newURL,
                state: state,
                code_challenge: codeChallenge,
                response_type: 'code',
                code_challenge_method: 'S256',
                client_id:  config.authen.client_id+""
               
            })
            console.log(urlReturn)
           window.open(urlReturn, "_self");
        },
        async authenticateUrlRegister(utmSource, utmMedium, feature) {
            const codeVerifier = randomString(56);
            const codeChallenge = PKCEencoding(codeVerifier);
            const newURL = buildURLWithUTMParams(config.authen.redirect_uri, utmSource, utmMedium, feature, 'LOGIN');
            localStorage.setItem(storage.code_verifier, codeVerifier);
            const state = randomString(Number(config.authen.length));
            const urlReturn = config.authen.sso_url+"/register/email" + "?" + new URLSearchParams({
                redirect_uri: newURL,
                state: state,
                code_challenge: codeChallenge,
                response_type: 'code',
                code_challenge_method: 'S256',
                client_id:  config.authen.client_id+""
               
            })
            console.log(urlReturn)
           window.open(urlReturn, "_self");
        },
        async checkCode(code, codeVerifier, utmSource, utmMedium, feature) {
            this.getAccessToken(code, codeVerifier,utmSource, utmMedium, feature);
           
        },
        async getAccessToken(code, codeVerifier,utmSource, utmMedium, feature){
            const newURL = buildURLWithUTMParams(config.authen.redirect_uri, utmSource, utmMedium,feature, 'LOGIN');
            return ApiServices().oauthToken(code, codeVerifier, newURL).then(res => res).catch(err => console.log(err))
        },
        async getProfile(){

           return ScoutConnectService.getProfile().then(res => res).catch(err =>  console.log(err))
        },
        async registerProfile(){
            return ScoutConnectService.registerProfile().then(res => res).catch(err => console.log(err))
         },
         async registerCompany(){
            return ScoutConnectService.registerCompany().then(res => res).catch(err => console.log(err))
         },
        async LogOut(){
            localStorage.clear();
            
            return true;
            
        }
    
    
    }
}