import { faBars, faHamburger } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppBar, Box, CssBaseline, Divider, Drawer, FormControl, IconButton, List, ListItem, ListItemButton, ListItemText, MenuItem, Select, Toolbar, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import "./talent-sauce-header.scss";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import config from "../../../../config";
import templateConfig from "../../../../template-config";
import UtilityService from "../../../../utils/utilities";
import JSON_LANG_TH from "../../../../assets/json/talent-sauce/landing-th.json";
import JSON_LANG_EN from "../../../../assets/json/talent-sauce/landing-en.json";
import languageService from "../../../../services/language.service";
import { ProviderContext } from "../../../../context/provider.context";
import { useLanguage } from "../../../../context/language.context";
interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    windows?: () => Window;
}

const drawerWidth = 240;


const TalentSauceHeaderComponent = (props: Props) => {
    const { windows } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate();
    const { language, setLanguage } = useLanguage();
    const [lang, setLang] = useState("en");
    const [translate, setTranslate] = useState(JSON_LANG_EN)
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    const [navItem, setNavItem] = useState(new Array())
    useEffect(() => {

        if (languageService.getLanguage() == 'en') {
            setTranslate(JSON_LANG_EN)
            const navMod = [JSON_LANG_EN.header.home,
            JSON_LANG_EN.header.pricing,
            JSON_LANG_EN.header.become_talent,
            JSON_LANG_EN.header.login
            ]
            setNavItem(navMod)
            setLang("en");
        } else {
            setLang("th");
            setTranslate(JSON_LANG_TH)
            const navMod = [JSON_LANG_TH.header.home,
            JSON_LANG_TH.header.pricing,
            JSON_LANG_TH.header.become_talent,
            JSON_LANG_TH.header.login
            ]
            setNavItem(navMod)
        }
    }, [])

    useEffect(() => {
        if (language) {
            if (language == 'en') {
                setTranslate(JSON_LANG_EN)
                const navMod = [JSON_LANG_EN.header.home,
                JSON_LANG_EN.header.pricing,
                JSON_LANG_EN.header.become_talent,
                JSON_LANG_EN.header.login
                ]
                setNavItem(navMod)
                setLang('en');
            } else {
                setTranslate(JSON_LANG_TH)
                const navMod = [JSON_LANG_TH.header.home,
                JSON_LANG_TH.header.pricing,
                JSON_LANG_TH.header.become_talent,
                JSON_LANG_TH.header.login
                ]
                setNavItem(navMod)
                setLang('th');
            }
            window.scrollTo(0, 0);
        }

    }, [language])


    const handleChange = (e) => {
        setLang(e.target.value);
        languageService.setLang(e.target.value);
        setLanguage(e.target.value);

        UtilityService().clickSendEvent("change_language_to_" + e.target.value, "click");
    }

    const drawer = (
        <div className="talent-sauce-header-main talent-sauce-header-mobile-main">
            <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
                <img src="./assets/images/main-logo-talent-sauce.png" onClick={() => clickMenu("Home")} className="talent-sauce-header-logo"></img>
                <Divider />
                <List className="talent-sauce-listbox-mobile-control">
                    {navItem.map((item) => (
                        <ListItem key={item} className="d-flex"  disablePadding>
                            <ListItemButton sx={{ textAlign: 'center' }} className="talent-sauce-header-mobile-button">
                                <span onClick={() => clickMenu(item)}>{item}</span>
                            </ListItemButton>
                        </ListItem>
                    ))}
                    <FormControl >
                        <Select
                            value={lang}
                            onChange={handleChange}
                            displayEmpty
                            className="talent-sauce-header-lang-control"
                            inputProps={{ 'aria-label': 'Without label' }}
                        >

                            <MenuItem value={"en"}>EN</MenuItem>
                            <MenuItem value={"th"}>ไทย</MenuItem>
                        </Select>
                    </FormControl>
                    <Button className="talent-sauce-header-line-oa-button"  onClick={() => clickLINEOA()}>
                        {translate.header.line_oa}
                    </Button>
                    <Button className="talent-suace-header-button-start" onClick={() => clickMenu("login")}>
                        {translate.header.get_started}
                    </Button>
                </List>
            </Box>
        </div>
    );

    const clickMenu = (url) => {

        if (url == translate.header.pricing) {
            UtilityService().clickSendEvent("header_package_pricing", "click");
            navigate("/pricing");
        } else if (url == "tryfree") {
            UtilityService().clickSendEvent("header_try_free", "click");
            navigate("/preview");
        } else if (url == translate.header.become_talent) {
            UtilityService().clickSendEvent("header_become_talent", "click");
            window.open(config.app.one_profile + "guest/talent-sauce/drop-resume", "blank");
        } else if (url == translate.header.login) {
            UtilityService().clickSendEvent("header_login", "click");
            navigate("/login");
        }
        else {
            UtilityService().clickSendEvent("home", "click");
            navigate("/");
        }

    }

    const clickLINEOA = () => {
        UtilityService().clickSendEvent("header_click_line_oa", "click");
        window.open("https://lin.ee/HwNciwt", "_self")
    }


    const container = windows !== undefined ? () => windows().document.body : undefined;
    return (
        <>
            {
                navItem &&
                <div className="talent-sauce-header-main">
                    <CssBaseline />
                    <AppBar component="nav">
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { md: 'none' } }}
                            >
                                <FontAwesomeIcon icon={faBars} className="talent-suace-header-hamburger" />
                            </IconButton>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'block' } }}
                            >
                                <img src="./assets/images/main-logo-talent-sauce.png" onClick={() => clickMenu("Home")} className="talent-sauce-header-logo"></img>
                            </Typography>

                            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }} className="talent-sauce-header-list-control">
                                {navItem.map((item) => (
                                    <span key={item} onClick={() => clickMenu(item)} className="talent-sauce-header-button-menu">
                                        {item}
                                    </span>
                                ))}
                                <Button className="talent-sauce-header-line-oa-button" onClick={clickLINEOA}>
                                    {translate.header.line_oa}
                                </Button>
                                <Button className="talent-suace-header-button-start" onClick={() => clickMenu("tryfree")}>
                                    {translate.header.get_started}
                                </Button>
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <Select
                                        value={lang}
                                        onChange={handleChange}
                                        displayEmpty
                                        className="talent-sauce-header-lang-control"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >

                                        <MenuItem value={"en"}>EN</MenuItem>
                                        <MenuItem value={"th"}>ไทย</MenuItem>
                                    </Select>
                                </FormControl>

                            </Box>


                        </Toolbar>
                    </AppBar>
                    <nav>
                        <Drawer
                            container={container}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: { xs: 'block', sm: 'block', md: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { xs: '100%', sm: '100%', md: '100%' } },
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </nav>
                </div>
            }

        </>
    )

}

export default TalentSauceHeaderComponent;