import { Button, Form } from "react-bootstrap";
import languageConfig from "../../../language-config";
import "../../features/features.scss";
import { Typeahead } from "react-bootstrap-typeahead";
import JDSuggestionModel from "../../../model/jd-suggestion.model";
import { useContext, useEffect, useState } from "react";
import { ProviderContext } from "../../../context/provider.context";
import ProfileSCModel from "../../../model/profile-sc.model";
import ConvertResultServices from "../../../services/convert-result.service";
import UtilityService from "../../../utils/utilities";
import ResultClinicComponent from "../result-clinic/result-clinic";
import InputJDAnalyzeModel from "../../../model/jd-analyze/input-jd-analyze.model";
import ResultJDAnalyzeModel from "../../../model/jd-analyze/result-jd-analyze.model";
import EXAMPLE_JSON from "../../../assets/json/example/jd-checker.json";
import LoadingComponent from "../loading/loading";
import TRICK_JSON from "../../../assets/json/trick-feature.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import templateConfig from "../../../template-config";
import GPTService from "../../../services/gpt.service";
import { useLocation, useNavigate } from "react-router-dom";
import LeelaService from "../../../services/leela.service";
import tokenService from "../../../services/token.service";
const JDCheckerComponent = ({ submitWithOutCredit, authenError }: any) => {
    const { profileModel, creditModel, setCreditModel }: any = useContext(ProviderContext)
    const [jdSuggestion, setJdSuggestion] = useState<JDSuggestionModel[]>([]);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [jobTitle, setJobTitle] = useState("");
    const [autocompleteList, setAutocompleteList] = useState<string[]>([]);
    const [jobExperience, setJobExperience] = useState("");
    const [jobSkill, setJobSkill] = useState("");
    const [language, setLanguage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState(EXAMPLE_JSON["result"].toString());
    const [isValidate, setIsValidate] = useState(false);
    const [profileMod, setProfileMod] = useState<ProfileSCModel>(profileModel);
    const { setResultRawString }: any = useContext(ProviderContext)
    const [isExample, setIsExample] = useState(true);
    const [isShare, setIsShare] = useState(false);
    const [jobID, setJobID] = useState("");
    const [jobDescription, setJobDescription] = useState("");
    const [isHR9, setIsHR9] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get("isHR9") == "true") {

            if (queryParams.get("job_id")) {
                setJobID(queryParams.get("job_id") || "")
                callApiJobs(queryParams.get("job_id"));
            }
            if (!profileModel) {
                // Navigate to "/xxx" with the updated query parameters
                navigate({
                    pathname: '/hr9',
                    search: queryParams.toString()
                });
            } else {
                setIsHR9(true)
            }
            setResult("")

        } else {
            setResult(EXAMPLE_JSON["result"]);
        }

    }, [location.search])
    const resetALL = () => {
        setJobSkill("");
        setJobTitle("");
        setJobExperience("");
        setLanguage("");
        setResult("");
        setIsValidate(false);

    }

    const callApiJobs = (id) => {

        LeelaService().getJob(tokenService.getHR9AccessToken(), tokenService.getHR9CompanyUUID(), id).then((res) => {
            console.log(res);
            if (res.name) {
                setJobTitle(res.name);
            }

            if(res.description){
                setJobDescription(res.description);
            }

        }).catch((error: any) => {

        })
    }
    const handleJobRoleChange = (event: any) => {
        setIsSubmiting(false);

        if (event) {
            try {
                if (event[0]["customOption"] != undefined) {
                    setJobTitle(event[0]["label"]);
                    console.log(event[0]["label"]);
                } else {
                    if (jdSuggestion) {
                        jdSuggestion.map((item: JDSuggestionModel, index: number) => {
                            if (item.job_title === event[0]) {
                                setJobTitle(item.job_title);
                                console.log(item.job_title);
                            }
                        })
                    }
                }
            } catch {

            }

        }
    }

    useEffect(() => {
        if (profileModel) {
            setProfileMod(profileModel)
        }
    }, [profileModel])

    useEffect(() => {
        getJDSuggestion();
    }, [])

    const getJDSuggestion = () => {
        GPTService.getJDSuggestion().then((res: any) => {

            if (res) {
                const resData = res.data as JDSuggestionModel[];
                setJdSuggestion(resData);
                const autoComp: string[] = [];
                resData.map((item: JDSuggestionModel, index: number) => {
                    autoComp.push(item.job_title);
                })
                setAutocompleteList(autoComp)
            }

        })
    }

    const onTextChange = (event: any, setTitle: any) => {
        const jt: string = event.target.value;
        setTitle(jt);
    }
    const handleLanguageChange = (event: any) => {
        setLanguage(event.target.value)
    }

    const submit = () => {
        setIsSubmiting(true);
        if (jobTitle && jobDescription && language) {
            setIsLoading(true);
            setResult("")
            setIsValidate(true);
            const objectResult: InputJDAnalyzeModel = {
                job_title: jobTitle,
                job_description: jobDescription,
                language: language,
                uuid: profileMod!.uuid
            }
            GPTService.postCustom(objectResult, "/job_description/analyze").then((res) => {
                if (res) {
                    if (result == "500") {
                        setResult("500")
                    } else if (res.status == 401) {
                        authenError();
                    } else {
                        const answerModel: ResultJDAnalyzeModel = res.data;
                        ConvertResultServices().JDChecker(answerModel, objectResult).then((result) => {
                            if (result) {
                                UtilityService().clickSendEvent("checking_jd_" + objectResult.job_title, "click")
                                setIsLoading(false);
                                setIsShare(true);
                                setResultRawString(result);
                                setResult(result);
                                setIsExample(false);
                                GPTService.getCredit().then((res3) => {

                                    if (res3) {
                                        setCreditModel(res3.data)
                                    }
                                })
                            }
                            setIsSubmiting(false);
                        });
                    }
                }
            }).catch((err) => {
                console.log(err);
            })

        }
    }

    return (

        <>
            {
                (result || isHR9) &&
                <div className="feature-control">


                    <div className={!isHR9 ? "feature-form-control" : "feature-form-control feature-form-control-hr9-iframe"}>
                        <div className="feature-form-left-control">
                            {
                                !isHR9 &&
                                <>
                                    <h1 className="feature-main-title">{languageConfig.content.jd_checker.title}</h1>
                                    <span className="feature-main-desc">{languageConfig.content.jd_checker.description}</span>
                                    <div className="feature-credit-usage-control">
                                        <FontAwesomeIcon icon={faCoins} className="feature-credit-usage-icon"></FontAwesomeIcon>
                                        <span className="feature-credit-usage-text">ใช้เครดิต:
                                            <span className="feature-credit-usage-text-bold-line-through">{templateConfig.credit_usage.ai_tools.standard} เครดิต</span>
                                            <span className="feature-credit-usage-text-bold">{templateConfig.credit_usage.ai_tools.discount} เครดิต</span></span>

                                    </div>
                                </>
                            }

                            {
                                isValidate && !isHR9 ?

                                    <>
                                        <div className="dashboard-form-item-submit-control">
                                            <div className="dashboard-form-item-submit-item">
                                                <div className="dashboard-form-item-submit-title">
                                                    Job Title (ตำแหน่งงาน)
                                                </div>
                                                <div className="dashboard-form-item-submit-desc">
                                                    {jobTitle}
                                                </div>
                                            </div>
                                            <div className="dashboard-form-item-submit-item">
                                                <div className="dashboard-form-item-submit-title">
                                                    Job Description (รายละเอียดงาน)
                                                </div>
                                                <div className="dashboard-form-item-submit-desc">
                                                    {jobDescription}
                                                </div>
                                            </div>
                                            <div className="dashboard-form-item-submit-item">
                                                <div className="dashboard-form-item-submit-title">
                                                    ภาษาที่ต้องการตรวจสอบ JD
                                                </div>
                                                <div className="dashboard-form-item-submit-desc">
                                                    {language}
                                                </div>
                                            </div>

                                            <div className="dashboard-form-item-submit-item">
                                                <Button className="feature-submit-button" onClick={() => resetALL()} aria-expanded={isLoading} aria-controls="jd-generator">เริ่มใหม่อีกครั้ง</Button>

                                            </div>
                                        </div>

                                    </> :
                                    !isValidate &&
                                    <div className="dashboard-form-item-control">
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">Job Title (ตำแหน่งงาน)<span className="dashboard-form-item-title-required">*</span></Form.Label>

                                            <Form.Group>
                                                {
                                                    isHR9 ?

                                                        <Form.Control type="text" className="dashboard-input" value={jobTitle} disabled></Form.Control> :
                                                        <Typeahead
                                                            onChange={(e) => handleJobRoleChange(e)}
                                                            id="valid-styles-example"
                                                            inputProps={{
                                                                className: 'dashboard-input',
                                                            }}
                                                            options={autocompleteList}
                                                            allowNew
                                                        />
                                                }

                                            </Form.Group>
                                            {
                                                (!jobTitle && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                    กรุณากรอก Job title
                                                </Form.Text>
                                            }
                                        </div>
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">Job Description (รายละเอียดงาน)<span className="dashboard-form-item-title-required">*</span></Form.Label>
                                            {
                                                isHR9 ?

                                                    <>
                                                    <div className="feature-textarea-description" dangerouslySetInnerHTML={{__html : jobDescription}} ></div>
                                                    </> :
                                                    <>
                                                        <Form.Control as="textarea" className="dashboard-input-textarea" cols={10} rows={5} onChange={(e) => onTextChange(e, setJobDescription)}></Form.Control>
                                                        {
                                                            (!jobDescription && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                                กรุณากรอก Job Description
                                                            </Form.Text>
                                                        }
                                                    </>

                                            }

                                        </div>

                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">ภาษาที่ต้องการตรวจสอบ JD<span className="dashboard-form-item-title-required">*</span></Form.Label>

                                            <Form.Select className="dashboard-input" onChange={handleLanguageChange} >
                                                <option value="">เลือกภาษา</option>
                                                <option value="THAI" >ไทย (Beta version)</option>
                                                <option value="ENGLISH" >English</option>
                                            </Form.Select>
                                            {
                                                (!language && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                    กรุณาเลือกภาษา
                                                </Form.Text>
                                            }
                                        </div>
                                        {
                                            profileMod &&
                                            <div className="d-flex">
                                                {
                                                    creditModel && creditModel.credit > 0 ?
                                                        <Button className="feature-submit-button" onClick={() => submit()} aria-expanded={isLoading} aria-controls="jd-generator">{languageConfig.content.jd_checker.button}</Button>
                                                        : <Button className="feature-submit-button-gray" onClick={submitWithOutCredit} aria-expanded={isLoading} aria-controls="jd-generator">{languageConfig.content.jd_checker.button}</Button>
                                                }


                                            </div>
                                        }

                                    </div>
                            }
                        </div>
                        <div className="">

                            {
                                isLoading && !result ? <>
                                    <LoadingComponent feature={TRICK_JSON[1].loading} listTrick={TRICK_JSON[1]} ></LoadingComponent>
                                </> :
                                    result && <ResultClinicComponent
                                        resultStr={result}
                                        type={languageConfig.content.jd_generator.title}
                                        isExample={isExample} isClose={false}
                                        isShare={isShare} isShareImg={false}
                                        isHR9={isHR9}
                                        setShowShare={setIsShare} ></ResultClinicComponent>
                            }

                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default JDCheckerComponent;