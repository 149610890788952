

// import "./result.scss";
import "./result-clinic.scss";
import NOT_FOUND from "../../../assets/images/not-found.png";
import { Button } from "react-bootstrap";
import { useContext, useEffect, useRef, useState } from "react";
import * as htmlToText from 'html-to-text';
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import { ProviderContext } from "../../../context/provider.context";
import { Alert, Snackbar } from "@mui/material";
const ResultClinicComponent = ({ resultStr, isExample, isShare, isShareImg, setShowShare, handleClose, type, isClose, isHR9 }: any) => {
    const htmlRef = useRef<HTMLDivElement>(null);
    const { resultRawString }: any = useContext(ProviderContext);
    const navigate = useNavigate();
    const [isModalSnack, setIsModalSnack] = useState(false);
    const location = useLocation();
    const convertNewLine = (message: string) => {


        return message.replaceAll("\n", "<br>");
    }
    const convertHtmlToPlainText = (html: string) => {
        return htmlToText.convert(html);
    };

    const handleCopyToClipboard = () => {
        if (htmlRef.current) {
            const plainText = convertHtmlToPlainText(htmlRef.current.innerHTML);
            navigator.clipboard.writeText(plainText);
            setIsModalSnack(true);
        }
    };
    const handleCloseSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setIsModalSnack(false);
      };
    useEffect(() => {
        if (resultStr) {
            console.log(resultStr);
        }
    }, [resultStr]);
    const refreshPageWithQueryParams = () => {
        window.location.replace(window.location.pathname + location.search);
    }

    const showShareImg = () => {
        setShowShare();
    }
    return (
            <div className="result-clinic-page">
                <div className={!isHR9 ? "result-right-control" : "result-right-control result-control-by-hr9"}>
                {isClose && <FontAwesomeIcon icon={faCircleXmark} className="result-close-button" onClick={handleClose}/>}
                
                   
                        <>
                            <div className="result-right-white-bg">
                                {
                                    isExample && !isHR9 &&    <span className="">ตัวอย่างผลลัพธ์</span>
                                }
                            {
                                !isHR9 &&
                                <span className="result-type-text">{type}</span>
                            }
                          
                                {
                                    (isShare && resultStr) &&

                                    <div className="result-share-control">
                                        <Button className="result-button-share" onClick={handleCopyToClipboard}>Copy to clipboard</Button>
                                        {
                                            isShareImg && <Button className="result-button-share" onClick={showShareImg}>ดูรูปภาพสำหรับแชร์</Button>
                                        }

                                    </div>
                                }
                                {(resultStr && (resultStr == "ไม่พบข้อมูล" || resultStr == "500")) ?
                                    <div className="result-not-found-control">

                                        <img src={NOT_FOUND} className="result-not-found-img"></img>
                                        <span className="result-not-found-text">ไม่พบข้อมูล กรุณาลองใหม่อีกครั้ง</span>
                                    </div>
                                    : resultStr && <div className="result-right-text-control">

                                        <div className="typewriter" ref={htmlRef}>
                                            <span dangerouslySetInnerHTML={{ __html: convertNewLine(resultStr) }}>
                                            </span>
                                        </div>

                                    </div>
                                }

                                {
                                    isHR9 && 
                                    <Button className="result-button-again" onClick={refreshPageWithQueryParams}>เริ่มใหม่อีกครั้ง</Button>
                                }

                            </div>
                        </>

                    {/* <img src={HR_IMG} className="result-hr-img"></img> */}
                </div>
                <Snackbar open={isModalSnack} autoHideDuration={3000}anchorOrigin={{vertical:'bottom', horizontal : 'center'}} onClose={handleCloseSnack} >
                <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                    Copied to clipboard
                </Alert>
            </Snackbar>
            </div >

    )
}

export default ResultClinicComponent;