import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { OauthModel } from "../model/oauth.model";
import storage from "../storage";
import TokenService from "./token.service";
import config from "../config";
import apiConfig from "../api-config";



const instanceTalentSearch = axios.create({
  baseURL: config.talentSeach.base_url,
})

const instanceGPTRefresh = axios.create({
  baseURL: config.authen.api.host_url,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

instanceTalentSearch.interceptors.request.use(
  (configs) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      configs.headers["x-api-key"] = config.talentSeach.x_api_key;
      configs.headers["scoutout-authorization"] = token; // for Node.js Express back-end
    }
    return configs;
  },
  (error) => {
    alert("กรุณา Login ใหม่");
    window.open('/authen')
    return Promise.reject(error);
  }
);

instanceTalentSearch.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== apiConfig.authen.token && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instanceGPTRefresh.post(apiConfig.authen.token, {
            grant_type: 'refresh_token',
            refresh_token: TokenService.getLocalRefreshToken(),
          });
          const token = rs.data as OauthModel
          TokenService.updateLocalAccessToken(token.access_token);

          return instanceTalentSearch(originalConfig);
        } catch (_error) {
        
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instanceTalentSearch;