const commonConfig = {
    base_url: process.env.REACT_APP_CONFIG_BASE_URL,
    indexFile: process.env.REACT_APP_INDEX_FILE,
    ga_4: process.env.REACT_APP_GA_4,
    logo_url: `${process.env.REACT_APP_WEBSITE_URL}${process.env.REACT_APP_LOGO_URL}`,
    app_name: process.env.REACT_APP_CONFIG_APP_NAME,
    origin_talent_search: process.env.REACT_APP_ORIGINAL_TALENT_SEARCH,
    content_gen_url: process.env.REACT_APP_CONTENT_GEN_URL,
    articles: {
        base_url: process.env.REACT_APP_CONTENT_GEN_URL,
        recommend: "/recommend",
        view_url: process.env.REACT_APP_ARTCLES_VIEW_LINK
    },
    authen: {
        sso_url: process.env.REACT_APP_AUTHEN_SSO_URL,
        client_id: process.env.REACT_APP_AUTHEN_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_AUTHEN_REDIRECT_URI,
        grant_type: process.env.REACT_APP_AUTHEN_GRANT_TYPE,
        length: process.env.REACT_APP_AUTHEN_LENGTH,
        api: {
            host_url: process.env.REACT_APP_AUTHEN_API_HOST_URL,
        },
        leela: {
            host_url: process.env.REACT_APP_AUTHEN_LEELA_URL,
        }
    },
    chat_gpt: {
        base_url: process.env.REACT_APP_CHAT_GPT_BASE_URL,
        resume_checker: {
            x_api_key: process.env.REACT_APP_RESUME_CHECKER_API_X_KEY,
            jobnow_plus: process.env.REACT_APP_RESUME_CHECKER_JOBNOW_PLUS,
            base_url: process.env.REACT_APP_RESUME_CHECKER_API_URL
        },
        upload_resume: {
            type: process.env.REACT_APP_UPLOAD_RESUME_TYPE,
            x_api_key: process.env.REACT_APP_UPLOAD_RESUME_X_API_KEY,
            base_url: process.env.REACT_APP_UPLOAD_RESUME_BASE_URL
        }
    },
    talentSeach: {
        base_url: process.env.REACT_APP_TALENT_SEARCH_BASE_URL,
        x_api_key: process.env.REACT_APP_TALENT_SEARCH_X_API_KEY
    },
    app: {
        one_profile: process.env.REACT_APP_OTHER_LINK_ONE_PROFILE,
        talent_search: process.env.REACT_APP_OTHER_LINK_TALENT_SEARCH,
        talent_search_bookmark: process.env.REACT_APP_OTHER_LINK_TALENT_SEARCH_BOOKMARK,
        home: process.env.REACT_APP_OTHER_LINK_HOME,
        why: process.env.REACT_APP_OTHER_LINK_WHY,
        features: process.env.REACT_APP_OTHER_LINK_FEATURES,
        hr9_services: process.env.REACT_APP_OTHER_LINK_HR9_SERVICE,
        hr9_landing: process.env.REACT_APP_OTHER_LINK_HR9_LANDING
    }
};

// Environment-specific overrides (only add values that differ)
const environmentOverrides: Record<string, Partial<typeof commonConfig>> = {
    ts_local: {
        app_name: 'TALENTSAUCE',
        ga_4: process.env.REACT_APP_GA_4,
    },
    ts_test: {
        app_name: 'TALENTSAUCE',
    },
    ts_prod: {
        app_name: 'TALENTSAUCE',
    }
};

// Generate final config by merging the common config with any environment-specific overrides
const getConfig = () => {
    const env = process.env.REACT_APP_ENVIRONMENT || 'test';
    return {
        ...commonConfig,
        ...(environmentOverrides[env] || {}),
        MAX_ATTACHMENT_SIZE: 5000000,
    };
};

const config = getConfig();

export default config;
